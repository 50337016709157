<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.userName"
          class="w-200 ml-5 mb-5"
          placeholder='送评人昵称或userID或手机号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.orderNo"
          class="w-200 ml-5 mb-5"
          placeholder='订单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.personApplyNo"
          class="w-200 ml-5 mb-5"
          placeholder='D类评级单号'
      ></a-input>
      <a-input
          allowClear
          v-model="params.productName"
          class="w-200 ml-5 mb-5"
          placeholder='产品名称'
      ></a-input>
      <a-input
          allowClear
          v-model="params.shortCode"
          class="w-200 ml-5 mb-5"
          placeholder='短码'
      ></a-input>
      <a-input
          allowClear
          v-model="params.expressNo"
          class="w-200 ml-5 mb-5"
          placeholder='物流单号'
      ></a-input>
      <a-select
          allowClear
          showSearch
          class="w-200 ml-5 mb-5"
          v-model="params.ratingABCDType"
          placeholder="评级类型"
      >
        <a-select-option
            v-for="item of dqCheckPayTypeListByAD"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-select
          allowClear
          showSearch
          class="w-300 ml-5 mb-5"
          v-model="params.ratingStatus"
          placeholder="评级状态"
      >
        <a-select-option
            v-for="item of ratingStatusTypeList"
            :key="item.value"
            :value="item.value"
        >{{ item.name }}</a-select-option>
      </a-select>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
          @changeSuccess="getList"
      />
    </div>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/checkManage/ratingManage/_components/TableList"
import {dqCheckPayTypeListByAD, RATE_STATUS_TYPE_LIST} from "@/views/cmsPage/checkManage/_data"

export default {
  components: {
    TableList,
  },
  data() {
    return {
      dqCheckPayTypeListByAD,
      data: [],
      ratingStatusTypeList: RATE_STATUS_TYPE_LIST,
      tempData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        ratingABCDType: undefined,
        orderNo: undefined,
        personApplyNo: undefined,
        productName: undefined,
        userName: undefined,
        shortCode: undefined,
        ratingStatus: undefined,
        ratingId: undefined,
        expressNo: undefined,
        pageNum: 1,
        pageSize: 10,
      },
    }
  },
  async mounted() {
    const { ratingId } = this.$route.query;
    this.$set(this.params, "ratingId", ratingId);
    await this.getList()
  },
  methods: {
    /** 翻页 */
    handleChangePage(page) {
      this.params.ratingId = undefined
      const { current } = page
      this.$set(this.pagination, "current", current)
      this.$set(this.params, "pageNum", current)
      this.getList()
    },
    /** 搜索 */
    search() {
      this.params.ratingId = undefined
      this.$set(this.pagination, "current", 1)
      this.$set(this.params, "pageNum", 1)
      this.getList()
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await this.axios("/dq_admin/rating/list", {
        params: {...this.params},
      })
      this.$loading.hide()
      if (res.status != 200) return
      this.data = res.data.records
      this.pagination.total = res.data.total
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
