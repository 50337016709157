<template>
  <div>
    <a-table
        :columns="tableList"
        :pagination="pagination"
        :data-source="list"
        :rowKey="(record, index) => index"
        @change="changePage"
    >
<!--   产品相关   -->
      <div slot="itemProductNameSlot" slot-scope="row">
        <div v-if="row.productName">{{ row.productName || '斗泉渠道代理' }}</div>
        <div v-if="row.userId"><span class="color-green">{{ `${row.userName || ''}【${row.userId}】` }}</span></div>
      </div>
<!--  订单编号或D类订单    -->
      <div slot="itemOrderNoSlot" slot-scope="row">
        <div>
          <a-tooltip>
            <template slot="title">
              {{ computedRateServicePayTypeTextByDesc(row.orderRatingServiceRank) }}
            </template>
            <span :class="dqCheckPayClassType[row.orderRatingServiceRank]">{{ computedRateServicePayTypeText(row.orderRatingServiceRank) }}</span>
          </a-tooltip>
        </div>
        <div class="mt-20">
          <span v-if="row.orderNo" class="cur-pot color-blue" @click="handleGoToOrderByABC(row.orderNo)">{{ row.orderNo }}</span>
          <span v-if="row.personApplyNo" class="cur-pot color-orange" @click="handleGoToOrderByD(row.personApplyNo)">{{ row.personApplyNo }}</span>
        </div>
      </div>
<!--  送评状态    -->
      <div slot="itemRatingStatusSlot" slot-scope="row">
        <div :class="rateStatusClassType[row.ratingStatus]">{{ computedRateStatusTypeText(row.ratingStatus) }}</div>
      </div>
<!--  评级物流    -->
      <div slot="itemSellerExpressNoSlot" slot-scope="row">
        <div>{{ row.sellerExpressNo }}</div>
        <div v-if="row.sellerLogisticsId">
          <a-button
              class="mt-10"
              size="small"
              @click="handleShowExpressLine(row)"
          >查看物流信息</a-button>
        </div>
      </div>
<!--  寄回物流    -->
      <div slot="itemSendExpressNoSlot" slot-scope="row">
        <div>{{ row.senderExpressNo }}</div>
        <div v-if="row.senderLogisticsId">
          <a-button
              class="mt-10"
              size="small"
              @click="handleShowExpressLineBySend(row)"
          >{{ row.senderLogisticsId && !row.senderExpressNo ? '查看寄回地址' : '查看寄回物流' }}</a-button>
        </div>
      </div>
<!--  评级费用    -->
      <div slot="itemRatingPriceSlot" slot-scope="row">
        <div>{{ row.ratingPrice }}</div>
        <div>
          <a-button
              class="mt-10"
              size="small"
              type="primary"
              @click="handleShowPriceLine(row)"
          >查看评级费用明细</a-button>
        </div>
      </div>
<!--   操作   -->
      <div slot="itemActionSlot" slot-scope="row">
        <div>
          <a-button
              class="mt-10"
              size="small"
              type="primary"
              @click="handleShowRateStep(row)"
          >查看评级进度</a-button>
        </div>
      </div>
    </a-table>
<!--  评级流程  -->
    <RateStatusStepPopup ref="rateStatusStepPopupEl"/>
<!--  评级金额明细  -->
    <RateStatusPriceLinePopup ref="rateStatusPriceLinePopupEl"/>
<!--  展示物流信息  -->
    <LogisticsInfoPopup ref="logisticsInfoPopupEl"/>
  </div>
</template>
<script>
import {
  dqArriveClassType,
  dqCheckPayClassType,
  dqCheckResultAfterByBuyerClassType,
  dqCheckResultAfterByBuyerTypeList,
  dqCheckResultClassType,
  dqCheckStatusClassType, RATE_STATUS_CLASS_TYPE,
  rateServicePayTypeMapText,
  rateServicePayTypeMapTextByDesc,
  rateStatusTypeListMapText
} from "@/views/cmsPage/checkManage/_data"
import {dqCheckRatingManageTableColumns} from "@/views/cmsPage/checkManage/ratingManage/_data"
import RateStatusStepPopup from "@/views/cmsPage/checkManage/ratingManage/_components/RateStatusStepPopup"
import RateStatusPriceLinePopup from "@/views/cmsPage/checkManage/ratingManage/_components/RateStatusPriceLinePopup"
import LogisticsInfoPopup from "@/views/cmsPage/checkManage/recordlist/_components/LogisticsInfoPopup"
export default {
  props: ['pagination', 'list'],
  components: {
    RateStatusStepPopup, RateStatusPriceLinePopup, LogisticsInfoPopup,
  },
  data() {
    return {
      dqArriveClassType,
      tableList: dqCheckRatingManageTableColumns,
      dqCheckResultClassType,
      dqCheckStatusClassType,
      dqCheckResultAfterByBuyerTypeList,
      dqCheckResultAfterByBuyerClassType,

      dqCheckPayClassType,
      rateStatusClassType: RATE_STATUS_CLASS_TYPE,
    }
  },
  computed: {
    computedRateServicePayTypeTextByDesc() {
      return (value) => rateServicePayTypeMapTextByDesc(value)
    },
    computedRateServicePayTypeText() {
      return (value) => rateServicePayTypeMapText(value)
    },
    computedRateStatusTypeText() {
      return (value) => rateStatusTypeListMapText(value)
    }
  },
  methods: {
    /** 查看物流详情 */
    handleShowExpressLine(row) {
      this.$refs.logisticsInfoPopupEl.showPopup(row.sellerLogisticsId)
    },
    /** 查看物流详情-send */
    handleShowExpressLineBySend(row) {
      this.$refs.logisticsInfoPopupEl.showPopup(row.senderLogisticsId, 'showChange', row.id)
    },
    /** 查看评级交易明细 */
    handleShowPriceLine(row) {
      this.$refs.rateStatusPriceLinePopupEl.showPopup(row.id)
    },
    /** 查看评级进度 */
    handleShowRateStep(row) {
      this.$refs.rateStatusStepPopupEl.showPopup(row.id, row)
    },
    /** 去往订单页面---ABC类 */
    handleGoToOrderByABC(orderNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/dqplatform/ordermannage?orderNo=${orderNo}`;
      window.open(url)
    },
    /** 去往订单页面---D类 */
    handleGoToOrderByD(personApplyNo) {
      const origin = location.origin
      const url = `${origin}/#/cms/personRatingApply/dlist?personApplyNo=${personApplyNo}`;
      window.open(url)
    },
    /** 翻页 */
    changePage(page) {
      this.$emit("changePage", page);
    },
    /** 成功 */
    handleChangeSuccess() {
      this.$emit("changeSuccess");
    },
  }
}
</script>
<style lang="scss" scoped>
.color-green {
  color: green;
}
.img-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: 50px;
  }
}
</style>