<template>
  <div>
    <a-modal
        :width="600"
        v-model="show"
        title="查看评级费用明细"
        @ok="show = !show"
    >
      <div class="rate-price-line-content">
        <a-table
            :columns="popupTableList"
            :data-source="popupData"
            :pagination="false"
            :rowKey="(record, index) => index"
        >
<!--     短码     -->
          <div slot="itemShortCodeSlot" slot-scope="row">
            <span>{{ row.shortCode + '-' + row.id }}</span>
          </div>
        </a-table>
      </div>
    </a-modal>
  </div>
</template>
<script>
import {getRatePriceListApi} from "@/views/cmsPage/checkManage/_apis"
import {sonTableColumns} from "@/views/cmsPage/checkManage/ratingManage/_data"
export default {
  data() {
    return {
      show: false,
      popupData: '',
      ratingId: '',
      popupTableList: sonTableColumns
    };
  },
  methods: {
    async showPopup(id) {
      this.ratingId = id
      await this.getRatePriceLine()
      this.show = true
    },
    /** 获取数据 */
    async getRatePriceLine() {
      this.$loading.show()
      const res = await getRatePriceListApi({ ratingId: this.ratingId })
      this.$loading.hide()
      if (res.status !== '200') return
      this.popupData = res.data
    },
    /** 取消 */
    handleCancelPrint() {
      this.show = false
      this.popupData = ''
    }
  },
};
</script>

<style lang="scss" scoped>
.rate-step-content {
  width: 100%;
}
.rate-step-content::v-deep .ant-steps-item-content {
  width: 80% !important;
}
</style>
